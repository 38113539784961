<template>
  <div>
    <FilterOmologazioni
      :fromRichieste="fromRichieste"
      :fromAttesa="fromAttesa"
      :fromCarico="fromCarico"
      :fromEsito="fromEsito"
      :fromApprovate="fromApprovate"
      :fromRifiutate="fromRifiutate"
      @getList="searchOmologazioni"
      @resetFilters="resetFilters"
      :denominazione_societa="denominazione_societa"
      :denominazione_omologatore="denominazione_omologatore"
      :id_disciplina="id_disciplina"
      :id_societa="id_societa"
      :id_omologatore="id_omologatore"
      @setDisciplina="setDisciplina"
      @setIdSocieta="setIdSocieta"
      @setIdOmologatore="setIdOmologatore"
    />
    <TableOmologazioni
      :fromRichieste="fromRichieste"
      :fromAttesa="fromAttesa"
      :fromCarico="fromCarico"
      :fromEsito="fromEsito"
      :fromApprovate="fromApprovate"
      :fromRifiutate="fromRifiutate"
      :rowstoskip="rowstoskip"
      :fetchrows="fetchrows"
      :sortcolumn="sortcolumn"
      :sortorder="sortorder"
      :currentpage="currentpage"
      :tutteLeStagioni="tutteLeStagioni"
      @getOmologazioni="getOmologazioni"
      @getList="searchOmologazioni"
      @setFetchRows="setFetchRows"
      @setCurrentPage="setCurrentPage"
      @setSortOrder="setSortOrder"
      @setTutteLeStagioni="setTutteLeStagioni"
    />
  </div>
</template>

<script>
import TableOmologazioni from "@/components/components-fit/omologazioni/TableOmologazioni.vue";
import FilterOmologazioni from "@/components/components-fit/omologazioni/FilterOmologazioni.vue";

import { defineComponent, getCurrentInstance, computed, watch, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "OmologazioniList",
  props: {
    fromRichieste: { type: Boolean },
    fromCarico: { type: Boolean },
    fromAttesa: { type: Boolean },
    fromEsito: { type: Boolean },
    fromApprovate: { type: Boolean },
    fromRifiutate: { type: Boolean },
    id_societa: { type: Number },
    id_omologatore: { type: Number },
    denominazione_societa: { type: String },
    denominazione_omologatore: { type: String },
    tutteLeStagioni: { type: Boolean },
    tipologie: { type: String },
    id_disciplina: { type: Number },
    rowstoskip: { type: Number },
    fetchrows: { type: Number },
    sortcolumn: { type: String },
    sortorder: { type: String },
    currentpage: { type: Number },
  },
  emits: [
    "resetFilters",
    "resetRowsToSkip",
    "setFetchRows",
    "setCurrentPage",
    "setSortOrder",
    "setDisciplina",
    "setIdSocieta",
    "setIdOmologatore",
  ],
  components: {
    TableOmologazioni,
    FilterOmologazioni,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);

    watch(stagione, async () => {
      getOmologazioni();
    });

    const localKeys = ref({});
    const getOmologazioni = async () => {
      localKeys.value = {
        anno: props.tutteLeStagioni ? 10000 : stagione.value,
        id_societa: props.id_societa,
        id_omologatore: props.id_omologatore,
        tipologie: props.tipologie,
        id_disciplina: props.id_disciplina,
        rowstoskip: props.rowstoskip,
        fetchrows: props.fetchrows,
        sortcolumn: props.sortcolumn,
        sortorder: props.sortorder,
      };
      await store.dispatch("setStoreListData", {
        keys: localKeys.value,
        apiLink: globalApi.OMOLOGAZIONI_ALL_LIST,
        itemName: "omologazioni_all_list",
      });
    };
    getOmologazioni();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedomologazioni_all_list")
    );

    const searchOmologazioni = async () => {
      if (!loaded.value) return;
      await emit("resetRowsToSkip");
      getOmologazioni();
    };

    const setFetchRows = (e) => {
      emit("setFetchRows", e);
    };
    const setCurrentPage = (page) => {
      emit("setCurrentPage", page);
    };
    const setSortOrder = (columnName, order) => {
      emit("setSortOrder", columnName, order);
    };

    const setTutteLeStagioni = async () => {
      await store.commit("setTutteLeStagioniRichiesteOmologazione");
      await store.commit("setTutteLeStagioniInCaricoOmologazione");
      await store.commit("setTutteLeStagioniInAttesaOmologazione");
      await store.commit("setTutteLeStagioniEsitoOmologazione");
      localKeys.value = {
        anno: props.tutteLeStagioni ? 10000 : stagione.value,
        id_societa: props.id_societa,
        id_omologatore: props.id_omologatore,
        tipologie: props.tipologie,
        id_disciplina: props.id_disciplina,
        rowstoskip: props.rowstoskip,
        fetchrows: props.fetchrows,
        sortcolumn: props.sortcolumn,
        sortorder: props.sortorder,
      };
      getOmologazioni();
    };

    //filtri
    const resetFilters = async () => {
      await emit("resetFilters");
      getOmologazioni();
    };

    const setDisciplina = (value) => {
      emit("setDisciplina", value);
    };
    const setIdSocieta = (societa) => {
      emit("setIdSocieta", societa);
    };
    const setIdOmologatore = (omologatore) => {
      emit("setIdOmologatore", omologatore);
    };

    return {
      getOmologazioni,
      resetFilters,
      searchOmologazioni,
      setFetchRows,
      setCurrentPage,
      setSortOrder,
      setTutteLeStagioni,
      setDisciplina,
      setIdSocieta,
      setIdOmologatore,
    };
  },
});
</script>
