<template>
  <div class="row mt-5 justify-content-between">
    <div class="col-6 text-start">
      <div class="btn btn-light text-gray-700 fs-6">
        <input
          class="form-check-input me-2"
          type="checkbox"
          @click="$emit('setTutteLeStagioni')"
          :checked="tutteLeStagioni"
        />visualizza tutte le stagioni
      </div>
    </div>
    <div class="col-6">
      <div class="text-end mt-4" style="margin-right: 19px">
        Visualizzati
        <b v-if="record > 0">
          {{ rowstoskip + fetchrows - (fetchrows - 1) }}
          -
          {{
            rowstoskip + fetchrows > record ? record : rowstoskip + fetchrows
          }}
        </b>
        <b v-else> 0 </b>
        elementi di <b>{{ record }}</b>
      </div>
    </div>
  </div>
  <br />
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="mx-auto my-auto text-center" v-if="!loaded">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div
    v-else-if="
      omologazioni_all_list.length === 0 &&
      loaded &&
      (status == 200 || status == 204)
    "
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <span class="text-gray-700 ps-3 fs-2"> Attenzione! Nessun risultato. </span>
  </div>
  <div
    v-else-if="status != 200 && status != 204"
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <span class="text-gray-700 ps-3 fs-2">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </span>
    <span
      type="button"
      data-bs-toggle=""
      data-bs-target=""
      aria-expanded="false"
      aria-controls=""
      class="text-gray-600 pt-4"
    >
      <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
        <i class="bi bi-house fs-2 text-gray-800"> </i
      ></router-link>
      <router-link v-else to="/bacheca-public">
        <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
    ></span>
  </div>
  <div v-else>
    <Datatable
      :table-header="tableHeader"
      :table-data="omologazioni_all_list"
      :total="record"
      :current-page="currentpage"
      :rows-per-page="fetchrows"
      :sortLabel="sortcolumn"
      :order="sortorder"
      @sort="setsortorder"
      @items-per-page-change="setfetchrows"
      @current-change="setcurrentpage"
    >
      <template v-slot:cell-anno="{ row: data }">{{ data.anno }}</template>
      <template v-slot:cell-societa="{ row: data }"
        ><div class="tab-long-xl">
          {{ data.societa }} ({{ data.cod_affiliazione }})
        </div></template
      >
      <template v-slot:cell-impianto="{ row: data }">
        <div class="tab-long-xl">{{ data.impianto }}</div>
      </template>
      <template v-slot:cell-disciplina="{ row: data }">{{
        data.disciplina
      }}</template>
      <template v-slot:cell-omologatore="{ row: data }">
        <div class="tab-long-xl">{{ data.omologatore }}</div></template
      >
      <template v-slot:cell-id_livello_omologazione="{ row: data }">
        <div class="text-center">
          {{ data.id_livello_omologazione }}
        </div></template
      >
      <template v-slot:cell-data_modifica_stato="{ row: data }">{{
        data.data_modifica_stato
      }}</template>
      <template v-slot:cell-stato="{ row: data }">
        <h1
          class="mb-0 badge"
          :class="
            data.stato === 'Approvata'
              ? 'badge-light-success'
              : data.stato === 'In attesa di verifica'
              ? 'badge-light-warning'
              : data.stato === 'Rifiutata'
              ? 'badge-light-danger'
              : data.stato === 'Richiesta approvazione'
              ? 'badge-light-primary'
              : data.stato === 'Presa in carico'
              ? 'badge-light'
              : ''
          "
        >
          {{ data.stato }}
        </h1>
      </template>
      <template v-slot:cell-options="{ row: data }">
        <div
          class="btn-group"
          @click="
            id_Omologazione = data.id_omologazione;
            denominazioneSelected = data.impianto;
            stato_omologazione = data.stato;
            livello = data.id_livello_omologazione;
          "
        >
          <button
            class="btn btn-sm dropdown"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            v-if="isEnabled('fnOmologazioniDropMenu')"
          >
            <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
          </button>
          <ul class="dropdown-menu">
            <li
              role="presentation"
              class="navi-item"
              v-if="
                (data.stato == 'Richiesta approvazione' ||
                  data.stato == 'Rifiutata') &&
                data.puo_modificare_stato
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <a
                  href="#"
                  class="navi-link"
                  @click="prendiInCarico(data.id_omologazione)"
                >
                  <i class="bi bi-plus-circle text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2">
                    Prendi in carico
                  </span>
                </a>
              </div>
            </li>
            <li
              role="presentation"
              class="navi-item"
              v-if="
                data.stato == 'Presa in carico' && data.puo_modificare_stato
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <a
                  href="#"
                  class="navi-link"
                  @click="annullaPresaInCarico(data.id_omologazione)"
                >
                  <i class="bi bi-dash-circle text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2">
                    Annulla presa in carico
                  </span>
                </a>
              </div>
            </li>
            <li
              role="presentation"
              class="navi-item"
              v-if="
                data.stato == 'Presa in carico' && data.puo_modificare_stato
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <a
                  href="#"
                  class="navi-link"
                  @click="inAttesaDiVerifica(data.id_omologazione)"
                >
                  <i class="bi bi-hourglass-split text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2">
                    In attesa di verifica
                  </span>
                </a>
              </div>
            </li>
            <li
              role="presentation"
              class="navi-item"
              v-if="
                data.stato == 'In attesa di verifica' &&
                data.puo_modificare_stato
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <a
                  href="#"
                  class="navi-link"
                  @click="approva(data.id_omologazione, data.id_impianto)"
                >
                  <i class="bi bi-check-circle text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2">
                    Approva omologazione
                  </span>
                </a>
              </div>
            </li>
            <li
              role="presentation"
              class="navi-item"
              v-if="
                data.stato == 'In attesa di verifica' &&
                data.puo_modificare_stato
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <a
                  href="#"
                  class="navi-link"
                  @click="rifiuta(data.id_omologazione, data.id_impianto)"
                >
                  <i class="bi bi-x-circle text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2"> Rifiuta </span>
                </a>
              </div>
            </li>
            <router-link
              v-if="data.stato != 'Approvata'"
              :to="{
                name: 'omologazioni-dettaglio',
                params: {
                  id: data.id_societa,
                  id_impianto: data.id_impianto,
                  id_omo: data.id_omologazione,
                  id_stato:
                    data.stato === 'In attesa di verifica'
                      ? 2
                      : data.stato === 'Rifiutata'
                      ? 3
                      : data.stato === 'Richiesta approvazione'
                      ? 5
                      : data.stato === 'Presa in carico'
                      ? 6
                      : 0,
                },
              }"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-pen text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Modifica </span>
                  </div>
                </div>
              </li></router-link
            >
            <li
              role="presentation"
              class="navi-item"
              v-if="
                data.stato == 'Approvata' && data.puo_annullareappr_modificaliv
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <a
                  href="#"
                  class="navi-link"
                  @click="annullaApprovazione(data.id_omologazione)"
                >
                  <i class="bi bi-x-lg text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2">
                    Annulla approvazione
                  </span>
                </a>
              </div>
            </li>
            <li
              role="presentation"
              class="navi-item"
              v-if="
                data.stato == 'Approvata' && data.puo_annullareappr_modificaliv
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <div
                  type="button"
                  class="navi-link"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_change_livello"
                >
                  <i class="bi bi-123 text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2">
                    Modifica livello
                  </span>
                </div>
              </div>
            </li>
            <router-link
              v-if="data.stato == 'Approvata'"
              :to="{
                name: 'omologazioni-dettaglio',
                params: {
                  id: data.id_societa,
                  id_impianto: data.id_impianto,
                  id_omo: data.id_omologazione,
                  id_stato: 1,
                },
              }"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-eye text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Visualizza </span>
                  </div>
                </div>
              </li></router-link
            >
            <a
              href=""
              data-bs-toggle="modal"
              data-bs-target="#modal_doc_impianti"
              @click="refresh = !refresh"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-files text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Documenti </span>
                  </div>
                </div>
              </li></a
            >
          </ul>
        </div>
      </template>
    </Datatable>
    <ModalDocImpianti
      :id_Omologazione="id_Omologazione"
      :stato="stato_omologazione"
      :refresh="refresh"
    />
    <ModalChangeLivello
      :id_Omologazione="id_Omologazione"
      :livello="livello"
      :denominazioneSelected="denominazioneSelected"
      @getList="$emit('getList')"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import Datatable from "../../kt-datatable/KTDatatable.vue";

import {
  inCaricoSiNoOmologazione,
  attesaVerificaOmologazione,
  approvaOmologazione,
  rifiutaOmologazione,
  annullaApprovazioneOmologazione,
} from "@/requests/impiantiOmologazione";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import ModalDocImpianti from "@/components/components-fit/impianti/ModalDocImpianti";
import ModalChangeLivello from "@/components/components-fit/omologazioni/ModalChangeLivello.vue";

export default defineComponent({
  name: "TableOmologazioniImpianti",

  components: {
    Datatable,
    Loading,
    ModalDocImpianti,
    ModalChangeLivello,
  },
  props: {
    fromRichieste: { type: Boolean },
    fromCarico: { type: Boolean },
    fromAttesa: { type: Boolean },
    fromEsito: { type: Boolean },
    fromApprovate: { type: Boolean },
    fromRifiutate: { type: Boolean },
    rowstoskip: { type: Number },
    fetchrows: { type: Number },
    sortcolumn: { type: String },
    sortorder: { type: String },
    currentpage: { type: Number },
    tutteLeStagioni: { type: Boolean },
  },
  emits: [
    "resetFilters",
    "setFetchRows",
    "setCurrentPage",
    "setSortOrder",
    "getList",
    "getOmologazioni",
    "setTutteLeStagioni",
  ],
  setup(props, { emit }) {
    const id_Omologazione = ref(null);
    const stato_omologazione = ref(null);
    const denominazioneSelected = ref(null);
    const livello = ref(null);

    const store = useStore();

    const isLoading = ref();

    const prendiInCarico = (id) => {
      isLoading.value = true;
      inCaricoSiNoOmologazione(id, true).then((res) => {
        if (res.status == 200) {
          alertSuccess("Omologazione presa in carico.");
          emit("getList");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        isLoading.value = false;
      });
    };

    const annullaPresaInCarico = (id) => {
      isLoading.value = true;
      inCaricoSiNoOmologazione(id, false).then((res) => {
        if (res.status == 200) {
          alertSuccess("Presa in carico annullata.");
          emit("getList");
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        isLoading.value = false;
      });
    };

    const inAttesaDiVerifica = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await attesaVerificaOmologazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Operazione effettuata correttamente.");
              emit("getList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const approva = (id, id_Impianto) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'omologazione verrà approvata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Approva",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await approvaOmologazione(id, id_Impianto).then((res) => {
            if (res.status == 200) {
              alertSuccess("Omologazione approvata correttamente.");
              emit("getList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const rifiuta = (id, id_Impianto) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'omologazione verrà rifiutata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Rifiuta",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await rifiutaOmologazione(id, id_Impianto).then((res) => {
            if (res.status == 200) {
              alertSuccess("Omologazione rifiutata correttamente.");
              emit("getList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const annullaApprovazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await annullaApprovazioneOmologazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Operazione effettuata correttamente.");
              emit("getList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const tableHeaderRichieste = ref([
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Impianto",
        key: "impianto",
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Data richiesta",
        key: "data_modifica_stato",
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const tableHeaderCarico = ref([
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Impianto",
        key: "impianto",
      },
      {
        name: "Omologatore",
        key: "omologatore",
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Data presa in carico",
        key: "data_modifica_stato",
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const tableHeaderAttesa = ref([
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Impianto",
        key: "impianto",
      },
      {
        name: "Omologatore",
        key: "omologatore",
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Data invio",
        key: "data_modifica_stato",
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const tableHeaderApprovate = ref([
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Impianto",
        key: "impianto",
      },
      {
        name: "Omologatore",
        key: "omologatore",
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Livello",
        key: "id_livello_omologazione",
        align: "center",
        sortable: false,
      },
      {
        name: "Data approvazione",
        key: "data_modifica_stato",
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const tableHeaderEsito = ref([
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Società",
        key: "societa",
      },
      {
        name: "Impianto",
        key: "impianto",
      },
      {
        name: "Omologatore",
        key: "omologatore",
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const tableHeader = ref(
      props.fromRichieste
        ? tableHeaderRichieste
        : props.fromCarico
        ? tableHeaderCarico
        : props.fromAttesa
        ? tableHeaderAttesa
        : props.fromApprovate
        ? tableHeaderApprovate
        : tableHeaderEsito
    );

    const setfetchrows = async (e) => {
      await emit("setFetchRows", e);
      emit("getOmologazioni");
    };
    const setcurrentpage = async (page) => {
      await emit("setCurrentPage", page);
      emit("getOmologazioni");
    };
    const setsortorder = async ({ columnName, order }) => {
      await emit("setSortOrder", columnName, order);
      emit("getOmologazioni");
    };

    const refresh = ref(false);

    return {
      omologazioni_all_list: computed(() =>
        store.getters.getStateFromName("resultsomologazioni_all_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedomologazioni_all_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordomologazioni_all_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusomologazioni_all_list")
      ),
      tableHeader,
      setcurrentpage,
      setsortorder,
      setfetchrows,

      isLoading,
      prendiInCarico,
      annullaPresaInCarico,
      inAttesaDiVerifica,
      approva,
      rifiuta,
      annullaApprovazione,

      id_Omologazione,
      denominazioneSelected,
      stato_omologazione,
      livello,

      isEnabled,
      refresh,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
});
</script>
