<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="cerca()">
          <div class="row mb-4">
            <div class="col-sm-5 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="denominazione_societa"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  :value="societa_nome"
                  autocomplete="off"
                />
                <svg
                  @click="
                    setIdSocieta({ name: null, value: null });
                    setIdOmologatore({ name: null, value: null });
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  resetSocieta();
                  setIdSocieta($event);
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
            <div class="col-sm-4 filter" v-if="!fromRichieste">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Omologatore"
                  name="nominativo_completo"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'persona';
                    getPersone($event.target.value);
                  "
                  :value="omologatore_nome"
                  autocomplete="off"
                />
                <svg
                  @click="setIdOmologatore({ name: null, value: null })"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                v-if="inputFocus === 'persona'"
                :list="persone"
                @hideList="
                  inputFocus = null;
                  resetPersone;
                "
                @selectedTesserato="
                  resetPersone();
                  setIdOmologatore($event);
                  inputFocus = null;
                "
              ></ListTesserati>
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="discipline"
                name="discipline"
                placeholder="Disciplina"
                :value="disciplina"
                @changeSelect="setDisciplina($event)"
              />
            </div>
          </div>

          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="cerca()"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                societa_id = null;
                societa_nome = '';
                omologatore_id = null;
                omologatore_nome = '';
                disciplina = null;
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import ListTesserati from "../utility/ListTesserati.vue";
import ListSocieta from "../utility/ListSocieta.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

import { format } from "@/composables/formatDate";

export default defineComponent({
  name: "filtri-omologazioni",
  emits: [
    "resetFilters",
    "getList",
    "setDisciplina",
    "setIdSocieta",
    "setIdOmologatore",
  ],
  props: {
    fromRichieste: { type: Boolean },
    fromCarico: { type: Boolean },
    fromAttesa: { type: Boolean },
    fromEsito: { type: Boolean },
    fromApprovate: { type: Boolean },
    fromRifiutate: { type: Boolean },
    id_societa: { type: Number },
    id_omologatore: { type: Number },
    denominazione_societa: { type: String },
    denominazione_omologatore: { type: String },
    id_disciplina: { type: Number },
  },
  components: {
    ListTesserati,
    ListSocieta,
    SelectInput,
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const keys = ref("d");
    if (!discipline.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagione = computed(() => store.getters.stagioneSelected);

    const getSocieta = (string) => {
      societa_nome.value = string;
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const getPersone = (string) => {
      omologatore_nome.value = string;
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getOmologatori", {
        string,
        stagione: stagione.value,
      });
    };
    const resetPersone = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const inputFocus = ref(null);

    const disciplina = ref(props.id_disciplina);
    const societa_id = ref(props.id_societa);
    const omologatore_id = ref(props.id_omologatore);
    const societa_nome = ref(props.denominazione_societa);
    const omologatore_nome = ref(props.denominazione_omologatore);

    const cerca = () => {
      emit("getList");
    };

    const setDisciplina = (value) => {
      disciplina.value = value;
      emit("setDisciplina", disciplina.value);
    };
    const setIdSocieta = ({ value, name }) => {
      societa_id.value = value;
      societa_nome.value = name;
      emit("setIdSocieta", {
        value: societa_id.value,
        name: societa_nome.value,
      });
    };
    const setIdOmologatore = ({ value, name }) => {
      omologatore_id.value = value;
      omologatore_nome.value = name;
      emit("setIdOmologatore", {
        value: omologatore_id.value,
        name: omologatore_nome.value,
      });
    };

    return {
      inputFocus,
      format,
      getSocieta,
      resetSocieta,
      getPersone,
      resetPersone,
      setDisciplina,
      setIdSocieta,
      setIdOmologatore,
      persone: computed(() => store.getters.personeSocieta),
      societa: computed(() => store.getters.societaNomeCodAff),
      stagione,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedomologazioni_all_list")
      ),
      discipline,
      cerca,
      societa_id,
      societa_nome,
      omologatore_id,
      omologatore_nome,
      disciplina,
    };
  },
});
</script>

<style scoped>
.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
}
.dp__icon {
  stroke: currentcolor;
  fill: currentcolor;
}
.dp__clear_icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--dp-icon-color);
}
.dp__input_icons {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 6px 12px;
  color: var(--dp-icon-color);
  box-sizing: content-box;
}
</style>
